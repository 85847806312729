import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <div style={containerStyle}>
        <div style={centerSectionStyle}>
           
          <nav>
            <ul style={navStyle}>
              <li><a href="https://chartinghq.com/about" style={linkStyle}>About</a></li> 
              <li><span style={separatorStyle}>|</span></li> 
              <li><a href="https://chartinghq.com/contactus" style={linkStyle}>Contact</a></li>
              <li><span style={separatorStyle}>|</span></li> 
              <li><a href="https://chartinghq.com/Privacy-polices/" style={linkStyle}>Privacy Policy</a></li>
              <li><span style={separatorStyle}>|</span></li>
              <li>
                <Link to="/terms-and-conditions" style={linkStyle}>Terms and Conditions</Link>
              </li>
            </ul>
          </nav>
          <p style={textStyle}>© 2024 ChartingHQ. All Rights Reserved</p>
           <div style={leftSectionStyle}>
          {/* <img 
          
            src="https://www.thegainers.in/wp-content/uploads/2021/02/logo-300x203.png" 
            alt="ThetaGainer Logo" 
            style={logoStyle} 
          /> */}
         
          
        </div>
          <p style={textStyle} >
            ChartingHQ is operated by <strong>Theta Gainers </strong>,  
            a FIU (Govt. of India) registered entity with <strong>GSTIN: 23AESPU7489P1Z3</strong>.
          </p>
        </div>
        
      </div>
    </footer>
  );
};

// Styling
const footerStyle = {
  backgroundColor: '#333',
  color: '#fff',
  padding: '20px 0',
  textAlign: 'center',
  borderRadius: '30px 30px 0 0',
  width: '100%',
  position: 'relative',
  bottom: '0',
  boxShadow: '0 -5px 10px rgba(0, 0, 0, 0.2)',
  zIndex: 3,
};

const containerStyle = {
  maxWidth: '1000px',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '0 20px',
};

const leftSectionStyle = {
  // display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start', // Aligns content to the left
  flex: 1,
 
};

const logoStyle = {
  height: '50px',
  marginBottom: '10px',
};

const legalTextStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  maxWidth: '400px',
};

const centerSectionStyle = {
  flex: 2,
  textAlign: 'center',
};

const textStyle = {
  margin: '5px 0',
};

const navStyle = {
  listStyleType: 'none',
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
};

const linkStyle = {
  color: '#fff',
  textDecoration: 'none',
  margin: '0 10px',
};

const separatorStyle = {
  color: '#fff',
  fontWeight: 'bold',
};

export default Footer;
