import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import GridLines from "components/Common/GridLine/index"; // Import the GridLines component

const TermsAndConditions = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box className="relative w-full min-h-screen bg-gray-100" sx={{ padding: "3rem"}}>
      {/* Background Gradient */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-blue-400"></div>

      {/* Grid Lines - Full Page Coverage */}
      <Box
        className="fixed top-0 left-0 w-full h-full z-0"
        style={{ pointerEvents: "none" }} 
      >
        <GridLines
          cellWidth={120}
          strokeWidth={1}
          height={dimensions.height}
          width={dimensions.width}
        />
      </Box>

      {/* Terms & Conditions Box */}
      <div className="relative container mx-auto p-6 bg-white shadow-md max-w-4xl mt-10 rounded-lg z-10">
        <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
        <p className="mb-4">Last Updated: </p>

        <p>
          Welcome to ChartingHQ! These Terms and Conditions govern your use of
          our website,{" "}
          <a href="https://dashboard.chartinghq.com/" className="text-blue-500">
            chartinghq.com
          </a>
          . By accessing or using our Website, you agree to abide by these
          terms. If you do not agree, please refrain from using the Website.
        </p>

        <h2 className="text-2xl font-semibold mt-6">1. Definitions</h2>
        <ul className="list-disc pl-6">
          <li>
            <strong>"We," "Us," "Our"</strong> refer to ChartingHQ.
          </li>
          <li>
            <strong>"User," "You," "Your"</strong> refer to anyone accessing or
            using the Website.
          </li>
          <li>
            <strong>"Services"</strong> refer to the tools, data, and resources
            provided through ChartingHQ.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6">2. Use of Website</h2>
        <ul className="list-disc pl-6">
          <li>
            You must be at least 18 years old or have parental consent to use
            our Website.
          </li>
          <li>You agree not to use the Website for any unlawful purpose.</li>
          <li>
            We reserve the right to suspend or terminate access if there is any
            violation of these terms.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6">
          3. Compliance with Indian Laws
        </h2>
        <p>
          This Website operates in accordance with Indian laws, including but
          not limited to:
        </p>
        <ul className="list-disc pl-6">
          <li>
            **The Information Technology Act, 2000** – Governing digital
            transactions and cybersecurity.
          </li>
          <li>
            **The Consumer Protection Act, 2019** – Ensuring consumer rights and
            fair digital transactions.
          </li>
          <li>
            **The Indian Penal Code (IPC), 1860** – Governing online fraud and
            cybercrimes.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6">
          4. Intellectual Property Rights
        </h2>
        <p>
          All content, graphics, trademarks, and intellectual property on the
          Website belong to ChartingHQ. You may not copy, reproduce, or
          distribute any content without prior written permission.
        </p>

        <h2 className="text-2xl font-semibold mt-6">5. User Responsibilities</h2>
        <p>
          You are responsible for maintaining the confidentiality of any login
          credentials. Any misuse of the Website, including unauthorized access,
          is strictly prohibited.
        </p>

        <h2 className="text-2xl font-semibold mt-6">6. Privacy Policy</h2>
        <p>
          Your use of the Website is also governed by our{" "}
          <a
            href="https://chartinghq.com/Privacy-polices/"
            className="text-blue-500"
          >
            Privacy Policy
          </a>
          .
        </p>

        <h2 className="text-2xl font-semibold mt-6">7. Limitation of Liability</h2>
        <p>
          We do not guarantee the accuracy or reliability of any data on the
          Website. ChartingHQ is not liable for any direct or indirect losses
          incurred from the use of our Services.
        </p>

        <h2 className="text-2xl font-semibold mt-6">8. Third-Party Links</h2>
        <p>
          Our Website may contain links to third-party websites. We are not
          responsible for their content or policies.
        </p>

        <h2 className="text-2xl font-semibold mt-6">9. Amendments</h2>
        <p>
          We may update these Terms at any time. Continued use of the Website
          means you accept the revised terms.
        </p>

        <h2 className="text-2xl font-semibold mt-6">
          10. Dispute Resolution & Governing Law
        </h2>
        <p>
          Any dispute arising from these Terms will be governed by **Indian
          law** and resolved in accordance with the applicable legal framework.
          In case of disputes, users may refer to:
        </p>
        <ul className="list-disc pl-6">
          <li>
            **The Consumer Disputes Redressal Forum** under The Consumer
            Protection Act, 2019.
          </li>
          <li>
            **The Information Technology Act, 2000** for matters related to
            digital transactions and cybersecurity.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6">11. Contact Us</h2>
        <p>
          If you have any questions regarding these Terms, please contact us at{" "}
          <strong>chartinghq@gmail.com</strong>.
        </p>
      </div>
    </Box>
  );
};

export default TermsAndConditions;
